<template>
    <div>
        <v-dialog v-model="edit_roadmap_card_model"
            persistent
            max-width="1200px"
            scrollable>
            <v-card>
                <v-card-title>
                    <v-text-field hide-details="auto" placeholder="Roadmap Title"
                        outlined dense
                        v-model="roadmap_form.name" 
                        @keypress="edit_roadmap_methods.editRoadmapName($event)"
                        :loading="roadmap_name_loading"
                        suffix="Press [Enter] to save"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-btn text small fab color="error"
                        @click="edit_roadmap_methods.closeEditRoadmapDialog()">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="8">
                            <div class="mt-4 mb-3">
                                <!-- Link Video -->
                                <v-text-field outlined dense label="Link to the Video" hide-details="false" v-model="roadmap_form.video_url"></v-text-field>
                                <div class="d-flex float-right mt-2">
                                    <div>
                                        <v-btn small color="error" class="mr-2" :disabled="roadmap_form.video_url != null ? false : true"
                                            @click="edit_roadmap_methods.removeVideolLink(roadmap)"
                                            :loading="roadmap_removelink_loading">
                                            Remove link
                                        </v-btn>
                                        <v-btn small color="primary" :disabled="roadmap_form.video_url != null ? true : false"
                                            :loading="roadmap_addlink_loading"
                                            @click="edit_roadmap_methods.addVideoLink(roadmap, roadmap_form.video_url)">
                                            Submit
                                        </v-btn>
                                    </div>
                                </div>
                                <div class="mt-2" v-if="roadmap_form.video_url != null">
                                    <v-icon class="mr-2 blue--text">mdi-play-speed</v-icon>
                                    <a :href="`${roadmap_form.video_url}`" target="_blank" class="text-decoration-none blue--text font-weight-medium">Click here to proceed to the video</a>                                   
                                </div>
                                <div class="mt-2 pl-2" v-else>
                                    <label>No Assigned Video Link</label>
                                </div>                                
                            </div>
                            <v-divider></v-divider>
                            <div class="mt-3">
                                <div>
                                    <v-text-field v-model="roadmap_form.task_name" dense outlined 
                                        suffix="Press [Enter] to add new task"
                                        @keypress="edit_roadmap_methods.addTask($event)"></v-text-field>
                                    <div>
                                        <v-icon>mdi-calendar-check</v-icon> <strong>Tasks</strong>
                                    </div>                                    
                                    <v-list two-line v-if="edit_roadmap_computed.getTasksFromStore.value.length > 0">
                                        <v-list-item v-for="(task, index) in edit_roadmap_computed.getTasksFromStore.value"
                                            :key="index">
                                            <v-list-item-action>
                                                <v-checkbox :input-value="task.is_done" @click="edit_roadmap_methods.updateTaskStatus(task)"></v-checkbox>                                                
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title :style="task.is_done == 1 ? 'text-decoration: line-through;' : ''">{{ task.tasks }}</v-list-item-title>
                                                <v-list-item-subtitle style="font-size: 12px;">{{ new Date(task.created_at).toLocaleDateString() }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-btn icon @click="edit_roadmap_methods.removeTaskEntry(task)">
                                                    <v-icon color="error lighten-1">mdi-close</v-icon>
                                                </v-btn>
                                            </v-list-item-action>                                            
                                        </v-list-item>
                                    </v-list>
                                    <div v-else class="mt-3 mb-3">
                                        <label>No Task Available</label>
                                    </div>
                                    <v-divider></v-divider>
                                </div>
                            </div>
                            <div class="mt-3">
                                <!-- Activity -->
                                <div><v-icon>mdi-forum-outline</v-icon><strong>Activity</strong></div>
                                <div class="mt-3">
                                    <v-textarea outlined></v-textarea>
                                </div>
                                <div class="mt-3">
                                    <v-list subheader two-line>
                                        <v-list-item>
                                            <v-list-item-avatar>
                                            <v-icon
                                                class="grey lighten-1"
                                                dark>mdi-folder</v-icon>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title>Ryan David M. Pillerin</v-list-item-title>
                                                <v-list-item-subtitle>This is a test</v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-action>
                                            <v-btn icon>
                                                <v-icon color="error">mdi-delete</v-icon>
                                            </v-btn>
                                            </v-list-item-action>
                                        </v-list-item>
                                    </v-list>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="4">
                            <div class="mt-3">
                                <label><strong>Percentage Implemented</strong></label>
                                <v-combobox
                                    v-model="selected_progress"
                                    :items="roadmap_progress_percentage"
                                    outlined dense
                                    @change="edit_roadmap_methods.setProgress(selected_progress)"></v-combobox>
                            </div>
                            <div>
                                <label><strong>Priority</strong></label>
                                <v-combobox
                                    v-model="selected_priority"
                                    :items="roadmap_priorities"
                                    item-text="priority"
                                    item-value="color"
                                    :background-color="selected_priority.color == null ? '#FFF' : selected_priority.color"
                                    outlined dense
                                    @change="edit_roadmap_methods.setPriority(selected_priority)">
                                    <template v-slot:selection="{ item }">
                                        <strong class="white--text">{{ item.priority }}</strong>
                                    </template>
                                </v-combobox>
                            </div>
                            <div>
                                <label><strong>Deadline</strong></label>
                                <v-menu
                                    absolute
                                    :close-on-content-click="false">
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"                                        
                                        class="mt-2"
                                        dense
                                        outlined                                        
                                        :value="roadmap.deadline_date"></v-text-field>
                                    </template>

                                    <v-card width="300px">
                                        <v-card-text>
                                            <v-date-picker
                                                v-model="picker"
                                                reactive
                                                :close-on-content-click="false">
                                            </v-date-picker>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                color="primary"
                                                small
                                                @click="edit_roadmap_methods.setDeadlineDate(picker)">
                                                Submit
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-menu>
                            </div>
                            <!-- Assigned Internal Users -->
                            <div>
                                <label><strong>Assigned To</strong></label>
                                <v-autocomplete
                                    v-model="selected_personnel"                                  
                                    dense
                                    outlined
                                    hide-details
                                    class="mt-1"
                                    :items="list_of_internal_users"
                                    item-text="name"
                                    item-value="id"
                                    return-object
                                    @change="edit_roadmap_methods.setInternalUser(selected_personnel)">
                                    <template v-slot:item="{ item }">                                       
                                        <v-avatar
                                            color="primary"
                                            size="25"
                                            class="mr-2 white--text">
                                            <small>{{ edit_roadmap_methods.getAssignedUserInitials(item.name) }}</small>
                                        </v-avatar>
                                        {{ item.name }}
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <v-chip close
                                            @click:close="edit_roadmap_methods.removeInternalUser(item)">
                                            <v-avatar color="primary"
                                                size="25"
                                                class="mr-2 white--text">
                                                <small>{{ edit_roadmap_methods.getAssignedUserInitials(item.name) }}</small>
                                            </v-avatar>
                                            {{ item.name }}
                                            </v-chip>
                                    </template>
                                </v-autocomplete>
                            </div>                                          
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from 'vue'

// Methods and Computed
import EditRoadmapMethods from './methods/editroadmap.methods'
import EditRoadmapComputed from './computed/editroadmap.computed'

// Vuex Store
import store from '@/states'
import InternalUsers from '@/modules/admin/components/InternalUsers.vue'

// Props and Emit
const props = defineProps(['edit_roadmap_card_dialog', 'roadmap_data', 'tasks_list'])
const emit = defineEmits(['closeEditRoamdapDialog'])

// Reference Variables
const edit_roadmap_card_model = ref(false)
const roadmap = ref(null)
const roadmap_form = ref({
    name: null,
    video_url: null,
    tagged_to: null,
})
const roadmap_name_loading = ref(false)
const roadmap_removelink_loading = ref(false)
const roadmap_addlink_loading = ref(false)
const roadmap_progress_percentage = ref([
    '-', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'
])
const selected_progress = ref('--')
const roadmap_priorities = ref([
    { color: '#A76571', priority: 'Priority 1' }, 
    { color: '#BD8C94', priority: 'Priority 2' },
    { color: '#D3B2B8', priority: 'Priority 3' }
])
const selected_priority = ref('')
const picker = ref(null)
const list_of_internal_users = ref(store.state.adminmain.listOfInternalUsers)
const selected_personnel = ref(null)


// Classes
const edit_roadmap_method_class = new EditRoadmapMethods({
    emit: emit,
    selected_roadmap: roadmap,
    // Loading 
    roadmap_name_loading: roadmap_name_loading,
    roadmap_removelink_loading: roadmap_removelink_loading,
    roadmap_addlink_loading: roadmap_addlink_loading,
    selected_priority: selected_priority,
    selected_progress: selected_progress,
    selected_personnel: selected_personnel
})
const edit_roadmap_computed_class = new EditRoadmapComputed()

// Methods
const edit_roadmap_methods = edit_roadmap_method_class.methods

// Computed
const edit_roadmap_computed = edit_roadmap_computed_class.computed_properties

// watcher
watch( props, () => {
    edit_roadmap_card_model.value = props.edit_roadmap_card_dialog
    if ( props.roadmap_data ) {
        roadmap.value = props.roadmap_data
        roadmap_form.value.name = props.roadmap_data.name
        roadmap_form.value.video_url = props.roadmap_data.video_url != null ? props.roadmap_data.video_url : null
        
        /** Tasks */
        const task_data = `course_id=${roadmap.value.course_id}&roadmap_id=${roadmap.value.id}&name=${roadmap.value.name}&user_id=${roadmap.value.user_id}`
        edit_roadmap_methods.getTasks(task_data)

        /** Percentage Implemented */
        selected_progress.value = roadmap.value.progress == null ? '--' : `${roadmap.value.progress}%`
        
        /** Priority */
        switch( roadmap.value.color ) {
            case '#A76571':
                selected_priority.value = {
                    color: roadmap.value.color,
                    priority: 'Priority 1'
                }
                break;
            case '#BD8C94':
            selected_priority.value = {
                    color: roadmap.value.color,
                    priority: 'Priority 2'
                }
                break;
            case '#D3B2B8':
            selected_priority.value = {
                    color: roadmap.value.color,
                    priority: 'Priority 3'
                }
                break;
        }
        
        /** Set Selected Personnel */               
        const assigned_user = list_of_internal_users.value.find( (item) => item.name.trim() == roadmap.value.tagged_to.trim())                
        if ( typeof assigned_user != 'undefined' ) {
            selected_personnel.value = assigned_user            
        }
    }
})

</script>